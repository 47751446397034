import { useState, useEffect } from 'react';
import { IconBtn } from '../../../../../../../../../components/buttons';
import { DropDown, Input2 } from '../../../../../../../../../components/inputs';
import prevIcon from "../../../../../../../../../../assets/img/prevPage.png";
import nextIcon from "../../../../../../../../../../assets/img/nextPage.png";

const parseDay = (dayId) => {
  const match = dayId?.match(/(\d+)-/);
  return match ? parseInt(match[1]) : null;
};

const parseMonth = (dayId) => {
  const match = dayId?.match(/-(\d+)-/);
  return match ? parseInt(match[1]) : null;
};

const parseYear = (dayId) => {
  const match = dayId?.match(/-(-?\d+)$/);
  return match ? parseInt(match[1]) : null;
};


export const DateScroller = ({dayParam, onChange, years, extraClass, edit=true}) => {    

    const yearsReversed = years.reverse();
    const moreClass = extraClass ? extraClass : "";

    const [yearValue, setYearValue] = useState(dayParam && dayParam !== "unsorted" ? parseYear(dayParam) : 1);

    const yearItem = {
      title: "Year",
      type: "number",
      value: yearValue,
      setValue: (e) => setYearValue(e.target.value),
      required: true,
    };  

    const [monthValue, setMonthValue] = useState(dayParam && dayParam !== "unsorted" ? parseMonth(dayParam) : 0);
    const [monthOptions, setMonthOptions] = useState([]);

    const monthItem = {
      title: "Month",
      type: "select",
      value: monthValue,
      setValue: (e) => setMonthValue(e.target.value),
      required: true,
      options: monthOptions
    };

    const [dayValue, setDayValue] = useState(dayParam && dayParam !== "unsorted" ? parseDay(dayParam) : 1);
    const [dayMax, setDayMax] = useState(1);

    const dayItem = {
      title: "Day",
      type: "number",
      value: dayValue,
      setValue: (e) => setDayValue(e.target.value),
      required: true,
      min: 1,
      max: dayMax
  };

    const previousDay = () => {
      if (dayValue > 1) {
        setDayValue(prev => prev - 1);
      } else if (monthValue > 0) {
        setDayValue(monthOptions[monthValue - 1].days);
        setMonthValue(prev => prev - 1);
      } else {
        setDayValue(monthOptions[monthOptions.length - 1].days);
        setMonthValue(monthOptions.length - 1);
        setYearValue(prev => prev === 1 ? -1 : prev - 1);
      }      
    }

    const nextDay = () => {
      if (dayValue < dayMax) {
        setDayValue(prev => prev + 1);
      } else if (monthValue < monthOptions.length - 1) {
        setDayValue(1);
        setMonthValue(prev => prev + 1);
      } else if (yearValue < 100) {
        setDayValue(1);
        setMonthValue(0);
        setYearValue(prev => prev === -1 ? 1 : prev + 1);
      }
    }

    useEffect(() => {
        onChange(dayValue, monthValue, yearValue);
    }, [dayValue, monthValue, yearValue, onChange]);

    useEffect(() => {
        for (let i = 0; i < yearsReversed.length; i++) {
          if (yearValue % yearsReversed[i].divisor === 0) {
            setMonthOptions(yearsReversed[i].months.map((item, index) => ({label: item.title, value: index, days: item.days})));
            setMonthValue(prev => {
              if (prev > yearsReversed[i].months.length - 1) {
                return yearsReversed[i].months.length - 1;
              }
              return prev;
            });
          }
        }
      }, [yearValue, yearsReversed]);      

    useEffect(() => {
        setDayMax(monthOptions[monthValue]?.days);
      }, [monthValue, monthOptions]);

    useEffect(() => {
      if (!dayParam) {
        onChange(dayValue, monthValue, yearValue);
      }
    }, [dayParam, dayValue, monthValue, yearValue, onChange]);

    return (        
        <menu className={`${moreClass} flex justify-center items-center`}>
            {edit && <>
            <IconBtn title="Previous day" extraClass="w-6 h-6" func={previousDay}><img className="w-6" src={prevIcon} alt="prev"/></IconBtn>
            <div className="relative grid grid-cols-4">
                    <Input2 extraClass="relative w-full" field={dayItem} onChange={dayItem.setValue} required={dayItem.required} label="none"/>
                    <DropDown extraClass="relative w-full col-span-2" value={monthItem.value} onChange={monthItem.setValue} options={monthOptions} required={monthItem.required} label="none"/>
                    <Input2 extraClass="relative" field={yearItem} onChange={yearItem.setValue} required={yearItem.required} label="none"/>
            </div>
            <IconBtn title="Next day" extraClass="w-6 h-6" func={nextDay}><img src={nextIcon} alt="next"/></IconBtn>
            </>}
            {
              !edit && <span className="whitespace-nowrap">{`${dayValue} ${monthOptions[monthValue]?.label} ${yearValue}`}</span>
            }
        </menu>
    )
}