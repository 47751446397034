import { forwardRef } from 'react';
import { InvisArticle } from './articles';
import { CheckBox, DropDown, DropDownExtended, Input2, TextArea } from './inputs';
import { DateScroller } from '../routes/realms/routes/realm/routes/timelines/routes/timeline/components/date-scroller';

export const Field = forwardRef(({ formMode, handleSubmit, item, extraClass }, ref) => {

    const moreClass = extraClass ? extraClass : "";

    return (
        <>
        { formMode ?
        <form onSubmit={handleSubmit} className={`${moreClass} flex justify-center w-full relative`}>
            { (item.type === "textarea") &&
            <TextArea ref={ref} extraClass="w-full" value={item.value} onChange={item.setValue} required={item.required} maxLength={item.max_length} nolabel={true}/>
            }
            { (item.type === "text" || item.type === "number") &&
            <Input2 ref={ref} extraClass="w-full" field={item} onChange={item.setValue} required={item.required} label="none"/>
            }
            { (item.type === "select") && 
            <DropDown ref={ref} extraClass="w-full" value={item.value} onChange={item.setValue} options={item.options} required={item.required} label="none"/>
            }
            { (item.type === "select-ext") && 
            <DropDownExtended ref={ref} item={item} label="none"/>
            }
            { (item.type === "checkbox") &&
            <CheckBox ref={ref} title={item.title} id={item.id} checked={item.value} onChange={item.setValue} nolabel={true}/>
            }
            { (item.type === "customdate") &&
            <DateScroller extraClass="w-[90%]" ref={ref} dayParam={item.dayParam} onChange={item.onChange} years={item.years}/>
            }
        </form>
        :
        <InvisArticle extraClass={moreClass}>
            <ValueDisplay item={item}/>
        </InvisArticle>
        }
        </>
    )

});

const ValueDisplay = ({ item }) => {

    if (item.type === "select" ) {
        return (
            <span className="whitespace-nowrap">{item.options.find(option => option.value.toString() === item.value.toString())?.label}</span>
        )
    } else if (item.type === "select-ext") {
        return (
            <span className="whitespace-nowrap">{item.value}</span>
        )
    } else if (item.type === "checkbox") {
        return (
            <span className="whitespace-nowrap">{item.value ? "Yes" : "No"}</span>
        )
    } else if (item.type === "customdate") {
        return (
            <span className="whitespace-nowrap"><DateScroller dayParam={item.dayParam} edit={false} years={item.years} onChange={() => {}}/></span>
        )
    } else if (item.type === "textarea") {
        return (
            <span>{item.value}</span>
        )
    }
    return (
            <span className="whitespace-nowrap">{item.value}</span>
    )
}