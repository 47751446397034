import { forwardRef } from "react";

const Label = ({title, id}) => {
  return (
    <label htmlFor={id} className="block font-sans text-left w-[95%]">
      {title}
    </label>
  );
}

const LeftLabel = ({title, id}) => {
  return (
    //nowrap
    <label htmlFor={id} className="font-sans text-right mr-1 whitespace-nowrap">
      {title}:
    </label>
  );
}

export const Input = (props) => {
  return (
    <>
    <Label title={props.title} id={props.id} />
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
        max={props.max}
        min={props.min}
        name={props.name}
        value={props.value}
        required={props.required}
        onChange={props.onChange}
        readOnly={props.readOnly}
        autoComplete={props.autoComplete}
        className="text-darker font-bold block bg-light w-[90%] m-px pl-1 h-6 rounded-sm"
      />
    </>
  );
};

export const Input2 = ({label = "top", field, onChange, extraClass}) => {
  
  const flexClass = label === "left" ? "grid grid-cols-3 w-full" : "flex flex-col";

  const moreClass = extraClass ? `${extraClass} ${flexClass} items-center` : flexClass;

  return (
    
    <div className={moreClass}>
    { label === "top" && <Label title={field.title} id={field.id}/> }
    { label === "left" && <LeftLabel title={field.title} id={field.id} /> }
      <input
        id={field.id}
        type={field.type}
        placeholder={field.placeholder}
        maxLength={field.max_length}
        value={field.value}
        required={field.required}
        min={field.min}
        max={field.max}
        onChange={onChange}
        title={field.title}
        className={`text-darker font-bold block bg-light w-[90%] m-px pl-1 col-span-2 h-6 rounded-sm`}
      />
    </div>
  );
};

export const TextArea = forwardRef((props, ref) => {
  return (
    <>
    {props.nolabel ? null : <Label title={props.title} id={props.id} />}
          <textarea
            ref={ref}
            className={`${props.extraClass} block font-bold bg-light text-darker m-px pl-1 rounded-sm min-h-[100px]`}
            value={props.value}
            required={props.required}
            id={props.id}
            maxLength={props.maxLength}
            onChange={props.onChange}
            readOnly={props.readOnly}
          ></textarea>           
    </>
  )
});

export const FileInput = ({title, id, onChange, label="top", extraClass, multiple}) => {

  const flexClass = label === "left" ? "flex items-center" : "flex flex-col";

  const moreClass = extraClass ? `${extraClass} ${flexClass} items-center` : flexClass;

  return (
    
    <div className={moreClass}>
    { label === "top" && <Label title={title} id={id}/> }
    { label === "left" && <LeftLabel title={title} id={id} /> }
          <input
            multiple={multiple}
            type="file"
            className={`w-[90%] font-bold bg-light text-darker m-px p-px h-6 rounded-sm`}
            id={id}
            onChange={onChange}
          ></input>           
    </div>
  )
}

export const DropDown = forwardRef(({ extraClass, title, id, value, options, onChange, label = "top", form = true }, ref) => {

  const flexClass = label === "left" ? "flex" : "flex flex-col";

  const moreClass = extraClass ? `${extraClass} ${flexClass} items-center` : flexClass;

  const width = form ? "w-[90%]" : "w-fit";

  return (
    <div className={moreClass}>
    { label === "top" && <Label title={title} id={id} /> }
    { label === "left" && <LeftLabel title={title} id={id} /> }
        <select
          title={title}
          ref={ref}
          onChange={onChange}
          value={value}
          className={`text-darker font-bold block bg-light m-px pl-1 h-6 rounded-sm ${width}`}
        >
          {options.map((item, index) => (
          <option key={index} value={item.value}>{item.label}</option>
        ))}
        </select>
    </div>
  );
});

export const DropDownExtended = forwardRef(({ item, label="top", form=true}, ref) => {

  const width = form ? "w-[90%]" : "w-fit";

  return (
    <div className="flex flex-col w-full items-center">
        <DropDown
            ref={ref}
            extraClass="w-full pb-1"
            value={item.value}
            onChange={(e) => 
                {
                    item.setValue(e.target.value);
                    if (e.target.value === item.options[0].value) {
                        item.setCustom(true);
                    } else {
                        item.setCustom(false);
                    }
                }
            }
            options={item.options}
            required={item.required}
            label={label}
            title={item.title}
            form={form}
            />
        {(item.custom) ? 
        <TextArea ref={ref} extraClass={width} value={item.value} onChange={(e) => item.setValue(e.target.value)} required={item.required} maxLength={item.max_length} label="none"/>
        :
        <p className={width}>{item.value}</p>
        }
    </div>
  );
});


export const CheckBox = ({title, id, checked, onChange, nolabel, extraClass}) => {

  const moreClass = extraClass ? extraClass : "";

  return (
    <>
    { !nolabel && <Label title={title} id={id} /> }
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        title={title}
        className={`${moreClass} text-darker font-bold block bg-light rounded-sm mx-auto`}
      />
    </>
  );
};
